<template>
  <div class="mx-auto my-4 px-4 sm:px-4 lg:px-8">
    <div class="space-y-6">
      <div class="flex justify-end gap-x-2">
        <base-input label="Filter Point" with-label>
          <base-select
            :options="selectIsPointOptions"
            :shadow="false"
            v-model="filter.is_point"
            v-on:change="handleFilter"
          />
        </base-input>
        <period-select
          v-model="filter.period_uuid"
          v-on:change="handleFilter"
        />
        <base-button :disabled="loadingExport" :loading="loadingExport" v-on:click="handleExportExcel">Export Excel</base-button>
      </div>
      <period-table
        v-if="filter.period_uuid"
        :page="page"
        v-on:change-page="handleChangePage"
      />
      <current-period-table
        v-else
        :page="page"
        v-on:change-page="handleChangePage"
      />
    </div>
    <loading v-if="isLoading" />
  </div>
</template>

<script>
import BaseInput from '@/components/base/BaseInput.vue';
import BaseSelect from '@/components/base/BaseSelect.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import PeriodSelect from '@/components/period/PeriodSelect.vue';
import CurrentPeriodTable from '@/components/stock/laporan-stock-global/CurrentPeriodTable.vue';
import PeriodTable from '@/components/stock/laporan-stock-global/PeriodTable.vue';
import { mapGetters, mapActions } from 'vuex';
import { downloadFile } from '@/services/utils.service'
import { now } from '@/services/date.service'

export default {
  components: {
    BaseSelect,
    BaseInput,
    BaseButton,
    PeriodSelect,
    CurrentPeriodTable,
    PeriodTable,
  },
  data() {
    return {
      loadingExport: false,
      intervalExport: null,
      filter: {
        is_point: null,
        period_uuid: null,
      },
      page: {
        size: 10,
        number: 1,
      },
    };
  },
  computed: {
    ...mapGetters({
      isLoadingCurrent: 'stocks/getLoading',
      isLoadingPeriod: 'stock_global_reports/getLoading',
    }),
    isLoading() {
      return this.isLoadingCurrent || this.isLoadingPeriod;
    },
    selectIsPointOptions() {
      return [
        {
          key: 'semua',
          value: null,
          label: 'Semua',
        },
        {
          key: 'point',
          value: true,
          label: 'Point',
        },
        {
          key: 'non-point',
          value: false,
          label: 'Non Point',
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      fetchCurrentGlobalReports: 'stocks/fetchGlobalReports',
      fetchPeriodGlobalReports: 'stock_global_reports/fetchStockGlobalReports',
      exportPeriodGlobalReports: 'stock_global_reports/exportStockGlobalReports',
      downloadStockGlobalReports: 'stock_global_reports/downloadStockGlobalReports',
      exportCurrentGlobalReports: 'stocks/exportGlobalReports',
      downloadCurrentGlobalReports: 'stocks/downloadGlobalReports',
    }),
    handleFilter() {
      this.loadReport({ resetPage: true });
    },
    handleChangePage(page) {
      this.page.number = page;

      this.loadReport();
    },
    handleExportExcel() {
      if (this.filter.period_uuid) {
        this.exportByPeriod()
      } else {
        this.exportCurrentPeriod()
      }
    },
    loadReport({ resetPage } = {}) {
      if (resetPage) {
        this.page.number = 1;
      }

      if (this.filter.period_uuid) {
        this.loadReportByPeriod();
      } else {
        this.loadReportCurrentPeriod();
      }
    },
    loadReportCurrentPeriod() {
      this.fetchCurrentGlobalReports({
        params: {
          'page[size]': this.page.size,
          'page[number]': this.page.number,
          'filter[is_point]': this.filter.is_point ? 1 : 0,
        },
      });
    },
    loadReportByPeriod() {
      this.fetchPeriodGlobalReports({
        params: {
          'page[size]': this.page.size,
          'page[number]': this.page.number,
          'filter[is_point]': this.filter.is_point ? 1 : 0,
          'filter[period_uuid]': this.filter.period_uuid,
          sort: 'product_code',
        },
      });
    },
    async exportByPeriod() {
      this.loadingExport = true

      await this.exportPeriodGlobalReports({
        params: {
          'page[size]': this.page.size,
          'page[number]': this.page.number,
          'filter[is_point]': this.filter.is_point ? 1 : 0,
          'filter[period_uuid]': this.filter.period_uuid,
          sort: 'product_code',
        },
      })

      this.startDownloadExportByPeriod()
    },
    async exportCurrentPeriod() {
      this.loadingExport = true

      await this.exportCurrentGlobalReports({
        params: {
          'page[size]': this.page.size,
          'page[number]': this.page.number,
          'filter[is_point]': this.filter.is_point ? 1 : 0,
        },
      })

      this.startDownloadExportCurrentPeriod()
    },
    async startDownloadExportByPeriod() {
      this.intervalExport = setInterval(async () => {
        await this.handleDownloadExportByPeriod()
      }, 5000)
    },
    async startDownloadExportCurrentPeriod() {
      this.intervalExport = setInterval(async () => {
        await this.handleDownloadExportCurrentPeriod()
      }, 5000)
    },
    async handleDownloadExportByPeriod() {
      const res = await this.downloadStockGlobalReports({
        params: {
          'page[size]': this.page.size,
          'page[number]': this.page.number,
          'filter[is_point]': this.filter.is_point ? 1 : 0,
          'filter[period_uuid]': this.filter.period_uuid,
          sort: 'product_code',
        },
      })
      
      if (!res.status) {
        downloadFile(res, `laporan_stock_global_${now()}.xlsx`)
        clearInterval(this.intervalExport)
        
        this.loadingExport = false
      }
    },
    async handleDownloadExportCurrentPeriod() {
      const res = await this.downloadCurrentGlobalReports({
        params: {
          'page[size]': this.page.size,
          'page[number]': this.page.number,
          'filter[is_point]': this.filter.is_point ? 1 : 0
        },
      })
      
      if (!res.status) {
        downloadFile(res, `laporan_stock_global_${now()}.xlsx`)
        clearInterval(this.intervalExport)
        
        this.loadingExport = false
      }
    }
  },
  created() {
    this.loadReport();
  },
};
</script>
