<template>
  <datatable
    :columns="columns"
    :data="globalReports.data"
    :total="globalReports.meta.total"
    :per-page="page.size"
    :current-page="page.number"
    empty-message="Laporan Stok Global Kosong"
    v-on:pagechanged="handleChangePage"
  />
</template>

<script>
import Datatable from '@/components/base/Datatable.vue';
import { mapGetters } from 'vuex';

export default {
  components: { Datatable },
  props: {
    page: {
      type: Object,
      required: true,
    },
  },
  emits: ['change-page'],
  computed: {
    ...mapGetters({
      globalReports: 'stocks/getGlobalReports',
    }),
    columns() {
      return [
        {
          id: 'product_code',
          name: 'Kode',
          value: (item) => item.product_code || '-',
        },
        {
          id: 'product_name',
          name: 'Nama',
          value: (item) => item.product_name || '-',
        },
        {
          id: 'product_price',
          name: 'HS 0',
          value: (item) =>
            item.product_price
              ? this.$options.filters.toCurrency(+item.product_price)
              : 0,
          theadClass: 'text-right',
          tbodyClass: 'text-right',
        },
        {
          id: 'stock_pusat',
          name: 'Pusat',
          value: (item) =>
            item.stock_pusat
              ? this.$options.filters.toCurrency(+item.stock_pusat)
              : 0,
          theadClass: 'text-right',
          tbodyClass: 'text-right',
        },
        {
          id: 'stock_kp',
          name: 'KP',
          value: (item) =>
            item.stock_kp
              ? this.$options.filters.toCurrency(+item.stock_kp)
              : 0,
          theadClass: 'text-right',
          tbodyClass: 'text-right',
        },
        {
          id: 'stock_sc',
          name: 'SC',
          value: (item) =>
            item.stock_sc
              ? this.$options.filters.toCurrency(+item.stock_sc)
              : 0,
          theadClass: 'text-right',
          tbodyClass: 'text-right',
        },
        {
          id: 'stock_perusahaan',
          name: 'Total Stock Perusahaan',
          value: (item) =>
            item.stock_perusahaan
              ? this.$options.filters.toCurrency(+item.stock_perusahaan)
              : 0,
          theadClass: 'text-right',
          tbodyClass: 'text-right',
        },
        {
          id: 'nilai_barang_perusahaan',
          name: 'Nilai Barang Perusahaan',
          value: (item) =>
            item.nilai_barang_perusahaan
              ? this.$options.filters.toCurrency(+item.nilai_barang_perusahaan)
              : 0,
          theadClass: 'text-right',
          tbodyClass: 'text-right',
        },
        {
          id: 'stock_stockist',
          name: 'Stockist',
          value: (item) =>
            item.stock_stockist
              ? this.$options.filters.toCurrency(+item.stock_stockist)
              : 0,
          theadClass: 'text-right',
          tbodyClass: 'text-right',
        },
        {
          id: 'nilai_barang_stockist',
          name: 'Nilai Barang Di Stockist',
          value: (item) =>
            item.nilai_barang_stockist
              ? this.$options.filters.toCurrency(+item.nilai_barang_stockist)
              : 0,
          theadClass: 'text-right',
          tbodyClass: 'text-right',
        },
      ];
    },
  },
  methods: {
    handleChangePage(page) {
      this.$emit('change-page', page);
    },
  },
};
</script>
