<template>
  <div>
    <datatable
      :columns="columns"
      :data="getStockGlobalReports.data"
      :total="getStockGlobalReports.meta.page.total"
      :per-page="page.size"
      :current-page="page.number"
      empty-message="Laporan Stok Global Kosong"
      v-on:pagechanged="handleChangePage"
    />
  </div>
</template>

<script>
import Datatable from '@/components/base/Datatable.vue';
import { mapGetters } from 'vuex';

export default {
  components: { Datatable },
  props: {
    page: {
      type: Object,
      required: true,
    },
  },
  emits: ['change-page'],
  computed: {
    ...mapGetters({
      getStockGlobalReports: 'stock_global_reports/getStockGlobalReports',
    }),
    columns() {
      return [
        {
          id: 'product_code',
          name: 'Kode',
          value: (item) => item.attributes.product_code || '-',
        },
        {
          id: 'product_name',
          name: 'Nama',
          value: (item) => item.attributes.product_name || '-',
        },
        {
          id: 'product_price',
          name: 'HS 0',
          value: (item) =>
            item.attributes.product_price
              ? this.$options.filters.toCurrency(+item.attributes.product_price)
              : 0,
          theadClass: 'text-right',
          tbodyClass: 'text-right',
        },
        {
          id: 'stock_pusat',
          name: 'Pusat',
          value: (item) =>
            item.attributes.stock_pusat
              ? this.$options.filters.toCurrency(+item.attributes.stock_pusat)
              : 0,
          theadClass: 'text-right',
          tbodyClass: 'text-right',
        },
        {
          id: 'stock_kp',
          name: 'KP',
          value: (item) =>
            item.attributes.stock_kp
              ? this.$options.filters.toCurrency(+item.attributes.stock_kp)
              : 0,
          theadClass: 'text-right',
          tbodyClass: 'text-right',
        },
        {
          id: 'stock_sc',
          name: 'SC',
          value: (item) =>
            item.attributes.stock_sc
              ? this.$options.filters.toCurrency(+item.attributes.stock_sc)
              : 0,
          theadClass: 'text-right',
          tbodyClass: 'text-right',
        },
        {
          id: 'stock_perusahaan',
          name: 'Total Stock Perusahaan',
          value: (item) =>
            item.attributes.stock_perusahaan
              ? this.$options.filters.toCurrency(
                  +item.attributes.stock_perusahaan
                )
              : 0,
          theadClass: 'text-right',
          tbodyClass: 'text-right',
        },
        {
          id: 'nilai_barang_perusahaan',
          name: 'Nilai Barang Perusahaan',
          value: (item) =>
            item.attributes.nilai_barang_perusahaan
              ? this.$options.filters.toCurrency(
                  +item.attributes.nilai_barang_perusahaan
                )
              : 0,
          theadClass: 'text-right',
          tbodyClass: 'text-right',
        },
        {
          id: 'stock_stockist',
          name: 'Stockist',
          value: (item) =>
            item.attributes.stock_stockist
              ? this.$options.filters.toCurrency(
                  +item.attributes.stock_stockist
                )
              : 0,
          theadClass: 'text-right',
          tbodyClass: 'text-right',
        },
        {
          id: 'nilai_barang_stockist',
          name: 'Nilai Barang Di Stockist',
          value: (item) =>
            item.attributes.nilai_barang_stockist
              ? this.$options.filters.toCurrency(
                  +item.attributes.nilai_barang_stockist
                )
              : 0,
          theadClass: 'text-right',
          tbodyClass: 'text-right',
        },
      ];
    },
  },
  methods: {
    handleChangePage(page) {
      this.$emit('change-page', page);
    },
  },
};
</script>
