<template>
  <div>
    <BaseModal
      overflowable
      :showing="visible"
      @close="onClose"
      :showClose="visible"
      :backgroundClose="true"
      size="max-w-5xl"
    >
      <div class="flex items-center justify-between">
        <div>
          <h3 class="text-lg font-bold leading-6 text-gray-900">
            {{ title }}
          </h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">
            {{ description }}
          </p>
        </div>
        <div>
          <img src="@/assets/images/logo.png" alt="" class="w-32" />
        </div>
      </div>
      <Datatable withOverflow class="mt-8" :paginated="false" :footer="false">
        <template v-slot:thead>
          <thead class="bg-gray-50">
            <tr>
              <th
                v-for="item in [
                  {
                    label: 'Kode',
                  },
                  {
                    label: 'Nama',
                  },
                  {
                    label: 'Status',
                  },
                  {
                    label: 'Jumlah',
                    classes: 'text-right',
                  },
                  {
                    label: 'Aksi',
                    classes: 'text-right',
                  },
                ]"
                scope="col"
                class="whitespace-nowrap px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                :class="item.classes"
                :key="item.label"
              >
                {{ item.label }}
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:tbody>
          <tbody>
            <template v-for="(movementDetail, i) in movementDetails">
              <tr :key="i">
                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                  <div class="relative" style="width: fit-content">
                    <base-select-search
                      :is-loading="false"
                      :data="getStocks.data ?? []"
                      label="product_code"
                      placeholder="Cari Kode"
                      v-model="movementDetail.product_id"
                      v-on:infinite-scroll="handleInfiniteScroll"
                      v-on:search="
                        (value) => handleSearch(value, movementDetail)
                      "
                      v-on:change="handleChange"
                    />
                  </div>
                </td>
                <td
                  :title="movementDetail?.product_id.attributes?.product_name"
                  style="
                    max-width: 200px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  "
                  class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                >
                  <span
                    @click="onOpenDetailStock(movementDetail)"
                    class="cursor-pointer hover:text-gray-700"
                  >
                    {{ movementDetail?.product_id.attributes?.product_name }}
                  </span>
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                >
                  <base-select
                    :options="[
                      {
                        label: 'Pilih Status',
                        value: null,
                      },
                      {
                        label: 'Free',
                        value: 'free',
                      },
                      {
                        label: 'Reimburse',
                        value: 'reimburse',
                      },
                      {
                        label: 'Layak Dijual',
                        value: 'suitable_for_sale',
                      },
                      {
                        label: 'Tidak Layak Dijual',
                        value: 'unsuitable_for_sale',
                      },
                      {
                        label: 'Expired',
                        value: 'expired',
                      },
                      {
                        label: 'Rusak',
                        value: 'damaged',
                      },
                    ]"
                    v-model="movementDetail.product_status"
                  />
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                >
                  <input
                    type="text"
                    name="jumlah_barang"
                    ref="jumlah_barang"
                    placeholder="Jumlah"
                    v-model="movementDetail.product_qty"
                    class="block w-full border-0 p-0 text-right placeholder-gray-200 focus:ring-0 sm:text-sm"
                  />
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                >
                  <button type="button" @click="movementDetails.splice(i, 1)">
                    <Icon class="h-4 w-4" icon="heroicons:x-mark-20-solid" />
                  </button>
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </Datatable>
      <div class="mt-8 flex justify-between">
        <div>
          <base-button
            color="white"
            @click="
              movementDetails.push({
                product_id: {},
                product_qty: 0,
                product_status: null,
              })
            "
            type="button"
          >
            Tambah Produk
          </base-button>
        </div>
        <base-button @click="handleSave" type="button"> Submit </base-button>
      </div>
    </BaseModal>
    <StockDetailModal
      :with-stock-history="false"
      :visible="openDetail"
      @close="openDetail = false"
    />
  </div>
</template>
<script>
import BaseModal from '@/components/base/BaseModal.vue';
import Datatable from '@/components/base/Datatable.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import { mapGetters, mapActions } from 'vuex';
import BaseSelectSearch from '@/components/base/BaseSelectSearch.vue';
import BaseSelect from '@/components/base/BaseSelect.vue';
// import BaseInput from '@/components/base/BaseInput.vue';
import StockDetailModal from '@/components/stock/StockDetailModal.vue';

export default {
  name: 'StockClassificationModal',
  components: {
    BaseModal,
    Datatable,
    BaseButton,
    StockDetailModal,
    // BaseInput,
    BaseSelect,
    BaseSelectSearch,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Pilah Barang',
    },
    description: {
      type: String,
      default: 'Pilah Barang',
    },
  },
  data() {
    return {
      openDetail: false,
      filter: {
        search: null,
      },
      movementDetails: [
        {
          product_id: '',
          product_qty: 0,
          product_status: null,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getStockMovement: 'stock_movements/getStockMovement',
      getStocks: 'stocks/getStocks',
      me: 'auth/getUser',
    }),
    stockParams() {
      return {
        'filter[search]': this.filter.search,
        warehouse_id: this.me.current_warehouse,
        pageNumber: 1,
        pageSize: 5,
        include: 'product',
      };
    },
    selectStockOptions() {
      return [
        ...this.getStocks.data.map((stock) => {
          return {
            key: stock.attributes.product_code,
            value: stock.id,
            label: stock.attributes.product_code,
          };
        }),
      ];
    },
  },
  methods: {
    ...mapActions({
      classifyMovementDetail: 'stock_movements/classifyMovementDetail',
      fetchStocksByWarehouse: 'stocks/fetchStocksByWarehouse',
      fetchStockById: 'stocks/fetchStockById',
    }),
    handleSearch(value, movementDetail) {
      this.filter.search = value;
      this.fetchStockById({
        id: movementDetail.product_id.id,
        params: {
          // 'fields[stocks]': 'product_code,product_name,stock_qty,is_orderable,is_pre_orderable,total_weight,license_status'
        },
      }),
        this.fetchStocksByWarehouse(this.stockParams);
    },
    handleChange() {},
    handleChangeProduct() {},
    handleInfiniteScroll() {},
    onOpenDetailStock() {
      this.openDetail = true;
    },
    async handleSave() {
      const payload = {
        data: {
          type: 'stock-movements',
          attributes: {
            origin_office_id: this.me.office_id,
            destination_office_id: this.me.office_id,
            origin_warehouse_id: this.me.current_warehouse,
            destination_warehouse_id: this.me.current_warehouse,
            movement_details: this.movementDetails.map((detail) => {
              return {
                product_id: detail.product_id.relationships.product.data.id,
                product_qty: parseInt(detail.product_qty),
                product_status: detail.product_status,
              };
            }),
          },
        },
      };
      await this.classifyMovementDetail({
        payload,
      });
      this.movementDetails = [
        {
          product_id: '',
          product_qty: 0,
          product_status: null,
        },
      ];
      this.$emit('refetch');
      this.onClose({ allModal: true });
    },
    onClose(value) {
      this.$emit('close', value);
    },
  },
  created() {
    this.fetchStocksByWarehouse(this.stockParams);
  },
};
</script>
